import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0,2,3,4,6];

export const dictionary = {
		"/": [~8],
		"/dashboard": [9,[2]],
		"/dashboard/actions": [10,[2]],
		"/dashboard/actions/[actionId]/[sessionId]": [11,[2]],
		"/dashboard/assist": [12,[2]],
		"/dashboard/billing": [~13,[2]],
		"/dashboard/billing/success": [14,[2]],
		"/dashboard/calendar-log": [15,[2]],
		"/dashboard/contacts": [16,[2]],
		"/dashboard/emails": [17,[2]],
		"/dashboard/emails/[[folderExec]]/[folder]": [18,[2,3]],
		"/dashboard/exec/[execId]": [19,[2]],
		"/dashboard/schedule": [20,[2]],
		"/dashboard/schedule/new": [22,[2]],
		"/dashboard/schedule/[scheduleId]": [21,[2]],
		"/dashboard/settings": [~23,[2]],
		"/dashboard/settings/calendar/[execId]": [24,[2]],
		"/dashboard/tasks": [25,[2]],
		"/dashboard/tasks/new": [28,[2]],
		"/dashboard/tasks/[agendaId]": [26,[2]],
		"/dashboard/tasks/[agendaId]/view": [27,[2]],
		"/dashboard/team": [29,[2]],
		"/dashboard/team/add": [30,[2]],
		"/internal": [31,[4]],
		"/internal/organizations": [~32,[4]],
		"/internal/organizations/[orgId]": [~33,[4]],
		"/internal/users": [~34,[4]],
		"/internal/users/[execId]": [~35,[4]],
		"/invite/[shortCode]": [36,[,6],[5]],
		"/invite/[shortCode]/success": [37,[,6],[5]],
		"/join-org/[orgId]/[inviteId]": [~38],
		"/login": [39],
		"/welcome": [40,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';