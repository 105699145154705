import { PUBLIC_BASE_URL } from "$env/static/public";
import * as Sentry from "@sentry/sveltekit";
import { handleErrorWithSentry } from "@sentry/sveltekit";

Sentry.init({
    dsn: "https://0868f5a8df6a6f970e2fb38922407bee@o4506671581298688.ingest.sentry.io/4506671581429760",
    tracesSampleRate: 0.1,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.01,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    profilesSampleRate: 0.01,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [],

    enabled: PUBLIC_BASE_URL.includes("localhost") ? false : true,

    ignoreErrors: ["TypeError: Failed to fetch"],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
